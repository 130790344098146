const userData = {
    name: 'Supuni',
    email : 'supuni@shoutoutlabs.io'
}

const inboxData = [{
  channel: 'Web',
  inboxName: 'ABC Website'
}]

const mockGet = (data, failMockEnabled = false) => {
  return new Promise((r, reject) => {
    setTimeout(() => {
      const random = Math.round(Math.random() * 10);
      if (failMockEnabled && random === 1) {
        reject();
      } else {
        r(data);
      }
    }, 2000);
  });
};

const generateMockBillingData = () => {
  const mockData = [];
  for (let i = 1; i <= 10; i++) {
    mockData.push({
      invoiceID: i,
      issuedDate: new Date().toLocaleDateString(),
      total: Math.floor(Math.random() * 1000) + 100,
      status: Math.random() < 0.5 ? "Paid" : "Pending",
    });
  }
  return mockData.map(item => ({
    ...item,
    pay: "Pay",
    download: "Download",
  }));
};

const getMockUserData = () => {
  return mockGet(userData);
}

const getInboxData = () => {
  return mockGet(inboxData)
}
export {
    getMockUserData,
    getInboxData,
    generateMockBillingData
}
